.expandable-item-container {
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.expandable-item-container::-webkit-scrollbar {
    display: none;
}

/* Component Expandable Item */

.expandable-item-wrapper {
    width: 100%;
    margin-top: 10px;
}

.expandable-item-internal-container {
    position: relative;
    width: 97%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.085);
}

.expandable-item-cross-close-container {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.expandable-item-decorator,
.expandable-item-icon {
    margin-right: 8px;
}

.expandable-item-decorator {
    width: 4px;
    height: 50px;
}

.expandable-item-summary-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
    display: inline-block;
}

.expandable-item-arrow {
    position: absolute;
    bottom: 16px;
    right: 16px;
}

.expandable-item-cross-close-icon {
    font-size: 14px;
    color: #999;
}

.expandable-item-details {
    width: 96%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.085);
    padding: 5px 15px;
    margin-top: 8px;
    margin-bottom: 2px;
    margin-left: 3px;
    background-color: #fff;
}

.expandable-item-details-content {
    margin: 0;
    max-width: 350px;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
